import React, { useState } from 'react'
import { graphql, navigate } from 'gatsby'
import { useLunr } from 'react-lunr'

import Layout from '../components/Layout'
import SearchResultHero from '../components/Heroes/SearchResultsHero'
import {
  RegionContainer,
  NoResultsMessage,
} from '../components/styles/Utility.styles'
import SearchResultItem from '../components/SearchResultItem'
import PaginationControl from '../components/PaginatonControl'
import { isEdge } from '../util/helper'

const ITEMS_PER_PAGE = 50

const SearchResults = ({ data, location }) => {
  const [firstItemForPage, setFirstItemForPage] = useState(0)
  const [lastItemForPage, setLastItemForPage] = useState(ITEMS_PER_PAGE)
  const [query, setQuery] = useState(null)

  const params = new URLSearchParams(location.search.slice(1))
  const queryString = params.get('q') || ''

  const { store, index } = data.localSearchPages

  const results = useLunr(queryString, index, store)

  const paginationChange = page => {
    if (isEdge) {
      document.getElementById('ContentRegion').scrollIntoView()
    } else {
      document.getElementById('ContentRegion').scrollTo({ top: 0 })
    }
    navigate(`?page=${page}&q=${queryString}`)
  }

  return (
    <Layout>
      <SearchResultHero queryString={queryString} />
      <RegionContainer>
        {results?.length <= 0 && (
          <NoResultsMessage>
            No results found for search term &ldquo;{queryString}&rdquo;
          </NoResultsMessage>
        )}
        {results?.length > 0 &&
          results
            .slice(firstItemForPage, lastItemForPage)
            .map(result => (
              <SearchResultItem
                key={result.slug}
                result={result}
                queryString={queryString}
              />
            ))}
        <PaginationControl
          itemCount={results.length}
          itemsPerPage={ITEMS_PER_PAGE}
          setFirstItemForPage={setFirstItemForPage}
          setLastItemForPage={setLastItemForPage}
          paginationChangeAction={paginationChange}
        />
      </RegionContainer>
    </Layout>
  )
}

export default SearchResults

export const pageQuery = graphql`
  query {
    localSearchPages {
      store
      index
    }
  }
`
